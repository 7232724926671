import { useMemo, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { koKR } from '@mui/material/locale';
import koLocale from 'date-fns/locale/ko';
import { SnackbarProvider } from 'notistack';
import { themeSelector } from '@redux/theme/slice';
import ProtectedRoute from './components/ProtectedRoute';
import MuiXLicense from './muiXLicense';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const Layout = lazy(() => import('./components/layout'));
const Login = lazy(() => import('./containers/login'));
const Home = lazy(() => import('./containers/home'));
const Procedures = lazy(() => import('./containers/procedures'));
const Procedures2 = lazy(() => import('./containers/procedures/index_renew'));
// const ProcessList = lazy(() => import('./containers/process/list'));
// const Stock = lazy(() => import('./containers/stock'));
const StockMaterial = lazy(() => import('./containers/stock/material'));
const StockProduct = lazy(() => import('./containers/stock/product'));
const Warehouses = lazy(() => import('./containers/warehouses'));
const Items = lazy(() => import('./containers/warehouses/items'));
// const Orders = lazy(() => import('./containers/orders'));

function App() {
  const { mode } = useSelector(themeSelector);
  const theme = useMemo(() => createTheme({
    palette: {
      primary: {
        main: '#556cd6',
      },
      secondary: {
        main: '#19857b',
      },
      mode,
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'AritaDotum,NotoSans,"Malgun Gothic","맑은 고딕","Nanum Gothic","나눔 고딕","돋움",dotum,sans-serif',
            fontSize: '18px',
            padding: 'unset',
            lineHeight: 1,
          },
        },
      },
    },
  }, koKR), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={anchorOrigin}
        autoHideDuration={1500}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={koLocale}>
          <BrowserRouter>
            <Suspense fallback={null}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={(
                    <ProtectedRoute>
                      <Layout />
                      <MuiXLicense />
                    </ProtectedRoute>
                  )}
                >
                  {/* <Route index element={<Home />} /> */}
                  <Route index element={<Procedures />} />
                  <Route path="test" element={<Procedures2 />} />
                  <Route path="procedures" element={<Procedures />} />
                  {/* <Route path="stock" element={<Stock />} /> */}
                  <Route path="stock/material" element={<StockMaterial />} />
                  <Route path="stock/product" element={<StockProduct />} />
                  <Route path="warehouses" element={<Warehouses />} />
                  <Route path="warehouses/:id/items" element={<Items />} />
                </Route>
                <Route path="/*" element={<p>404</p>} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
