import { configureStore } from '@reduxjs/toolkit';
import loadingReducer from './features/loading/slice';
import loginReducer from './features/login/slice';
import themeReducer from './features/theme/slice';
import inputReducer from './features/input/slice';

export default configureStore({
  reducer: {
    loading: loadingReducer,
    login: loginReducer,
    theme: themeReducer,
    input: inputReducer,
  },
});
