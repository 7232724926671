import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY)),
  },
  reducers: {
    setUserAction: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUserAction } = loginSlice.actions;
export const loginSelector = (state) => state.login;
export default loginSlice.reducer;
