import { createSlice } from '@reduxjs/toolkit';

export const inputSlice = createSlice({
  name: 'input',
  initialState: {
    billNo: '',
    searchFlag: 0,
  },
  reducers: {
    setBillNoAction: (state, action) => {
      state.billNo = action.payload;
    },
    clearBillNoAction: (state) => {
      state.billNo = '';
    },
    searchAction: (state) => {
      state.searchFlag += 1;
    },
  },
});

export const {
  setBillNoAction,
  clearBillNoAction,
  searchAction,
} = inputSlice.actions;
export const inputSelector = (state) => state.input;
export default inputSlice.reducer;
