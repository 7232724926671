import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loginSelector } from '../redux/features/login/slice';

function ProtectedRoute({ children }) {
  const { user } = useSelector(loginSelector);

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
